import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from '../components/button'

export const ItemContainer = ({ children, className }) => (
  <div className={['blog-post-container', className].join(' ')}>
    <div
      className={'shadow-md border-radius bg-white'}
      style={{ backgroundColor: 'white' }}
    >
      {children}
    </div>
  </div>
)

export const PublishDate = ({ children }) => (
  <p className="publish-date">{children}</p>
)

export const Heading = ({ children }) => <h2 className="heading">{children}</h2>

export const Excerpt = ({ children }) =>
  children ? <p className="excerpt">{children}</p> : null

const BlogPostItem = ({
  title,
  slug,
  image,
  publishDate,
  author,
  category,
  categorySlug,
  excerpt,
}) => {
  return (
    <ItemContainer>
      <GatsbyImage
        image={getImage(image)}
        style={{ height: '220px' }}
        alt={title}
      />
      <div className={'innerpadding'}>
        <PublishDate>
          {publishDate} by {author} in{' '}
          <Link
            to={'/blog/category/' + categorySlug}
            title={'View all posts in ' + category}
          >
            {category}
          </Link>
        </PublishDate>
        <Heading>{title}</Heading>
        <Excerpt>{excerpt}</Excerpt>
        <Button
          to={'/blog/' + slug}
          color={'secondary'}
          title={'Read more about ' + title}
        >
          Read More
        </Button>
      </div>
    </ItemContainer>
  )
}

export default BlogPostItem
